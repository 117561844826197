import './Main.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faBullseye } from '@fortawesome/free-solid-svg-icons';
import ProductSlider from './Product-Slider/ProductSlider';

const Card = ({ title, image }) => {
    return (
      <div className="card">
        <img src={image} className="card-img-top" alt={title} />
        <div className="card-body">
          <h5 className="card-title text-center">{title}</h5>
        </div>
      </div>
    );
  };

function Main() {

  return (
    <>
    <ProductSlider />
    <div class="container background">

    <div className="mt-5 future background">
           <h1 className="future-head background">A FUTURE READY ENTERPRISE</h1>
           <div className="underline background"></div>
           <p className="future-p background" style={{marginTop:'20px'}}>OJAS Lubricants has been leading the way in developing and marketing technologically advanced products in step with innovations in the global lubricant's markets.</p>
      <div className="row second-row background">
        <div className="col-6 col-md-3 second-four-cards">
          <Card title="R&D" image="./Labrotary.jpeg" />
        </div>
        <div className="col-6 col-md-3 second-four-cards">
          <Card title="IMPACT" image="./Impact.jpeg" />
        </div>
        <div className="col-6 col-md-3 second-four-cards">
          <Card title="QUALITY" image="./Quality.jpeg" />
        </div>
        <div className="col-6 col-md-3 second-four-cards">
          <Card title="INFRA" image="./Infrastructure.jpeg" />
        </div>
      </div>
    </div>

<div style={{marginTop:'50px'}}>
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6">
          <div className="container">
            <h2>A PERFECT MIX OF TECHNOLOGY</h2>
            <p>
              A PERFECT MIX OF TECHNOLOGY WE OJAS PETROCHEM ARE WELL KNOWN FOR PROVIDING AN EXTENSIVE VARIETY OF ENGINE OIL AND LUBRICANTS. OUR WIDE REACH IS OBTAINED FROM OUR TRUSTED AND SOLID SELLERS, WHO ARE PRESTIGIOUS FOR THEIR QUALITY ITEMS. THIS GUARANTEES THAT THE SCOPE OF OIL BASED GOODS AND LUBRICANTS PRESENTED BY US ARE AS PER THE GLOBAL NORM. OUR RANGE INCLUDES MOTOR OILS, TRANSFORMER OILS, MODERN OILS, WHITE OILS, RUST PREVENTIVE OILS, CUTTING OILS, AND MANY OTHERS. OUR LEGITIMATE QUALITY STEPS ASSIST US WITH KEEPING UP WITH OUR QUALITY AT EACH STAGE. OTHER THAN THIS, THE ADMINISTRATIONS OF EXPERIENCED INDUSTRY EXPERTS LIKEWISE PERMIT US TO IMPECCABLY DEAL WITH ELABORATE BUSINESS TASKS AS WELL AS GUARANTEE THE OFFERED FINISHED RESULTS ARE CONVEYED IN THE CARE PLAN AND FINISH CHOICES AS REQUESTED IN THE BUSINESS SECTORS. 
            </p>
          </div>
        </div>
        <div className="col-md-6">
        <div className="container">
          <img src="./TechCompany.png" alt=" " className="img-fluid" />
        </div>
        </div>
      </div>
    </div>

<div className="container" style={{marginTop:'20px'}}>
      <div className="row">
        <div className="col-md-6 mb-4 ">
          <div className="card text-center">
            <div className="card-body d-flex flex-column justify-content-center align-items-center">
              <FontAwesomeIcon icon={faEye} size="3x" className="mb-3" /> {/* Icon at the top center */}
              <h5 className="card-title">VISION</h5>
              <p className="card-text">Through our constant pursuit of innovation and use of state-of-the-art technology, OJAS Lubricants aims to contribute towards the inclusive growth of all our stakeholders Viz. customers, employees and society and make this world a more prosperous and healthier place for our future generations.</p>
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-4">
          {/* Second Card */}
          <div className="card text-center">
            <div className="card-body d-flex flex-column justify-content-center align-items-center">
              <FontAwesomeIcon icon={faBullseye} size="3x" className="mb-3" /> {/* Icon at the top center */}
              <h5 className="card-title">MISSION</h5>
              <p className="card-text">Our Mission is to deliver world-class products, services and solutions to our diverse clientele. Our products, solutions and innovations should add value at every stage of our business to our stakeholders, Technology partners, esteemed clients, invaluable employees and environment.</p>
            </div>
          </div>
        </div>
      </div>



    </div>

    <div className="container-fluid">
      <div className="row">
      <div className="col-md-6">
        <div className="container">
          <img src="./oil.png" alt=" " className="img-fluid" />
        </div>
        </div>
        <div className="col-md-6">
          {/* Content for the left column */}
          <div className="container" style={{marginTop:'20px'}}>
            <h2>Why Choose Us?</h2>
            <p>
            Ojas Petrochem is a leading provider of PAN India, end-to-end lubrication solutions under the brand name of "Ojas" for almost all types of Industrial and Automotive needs. Ojas Petrochem's core products include Automotive, Industrial and Specialty Lubricants. Ojas Petrochem's products conform to global quality standards and come with tested quality guarantees. Ojas Petrochem solutions' standard for quality, innovation and support has made benchmarks in the industry.
            </p>
          </div>
        </div>    
      </div>
    </div>
    </div>

  </div>
</>
  );
}

export default Main;
