import React from "react";
import Slider from "react-slick";
import "./ClientSlider.css"; // For custom styling

const ClientSlider = () => {
  const products = [
    { id: 1, img: "../logos/logo.png" },
    { id: 2, img: "../logos/logo1.png" },
    { id: 3, img: "../logos/logo12.png" },
    { id: 4, img: "../logos/logo3.gif" },
    { id: 5, img: "../logos/logo4.png" },
    { id: 6, img: "../logos/logo5.png" },
    { id: 7, img: "../logos/logo7.png" },
    { id: 8, img: "../logos/logo12.png" },
    { id: 9, img: "../logos/logo10.png" },
    { id: 10, img: "../logos/logo8.jpg" },
    
  ];

  const settings = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div className="custom-slider-container">
      <h2 style={{ padding: "10px" }}>Our Client's</h2>
      <Slider {...settings}>
        {products.map((product) => (
          <div key={product.id} className="custom-slider-item">
            <img src={product.img} alt={`Product ${product.id}`} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ClientSlider;
