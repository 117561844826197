import React from "react";
import Slider from "react-slick";
import "./ProductSlider.css"; // For custom styling

const ProductSlider = () => {
  const products = [
    { id: 1, name: "15W40", img: "PICTURES-OJAS/15W40/15W40.jpg" },
    { id: 2, name: "20W50", img: "PICTURES-OJAS/20W50/20W50.jpg" },
    { id: 3, name: "EP - 90", img: "PICTURES-OJAS/EP - 90/BHP_8119.jpg" },
    { id: 4, name: "MP3", img: "PICTURES-OJAS/GREASE MP3/BHP_8090.jpg" },
    { id: 5, name: "80W90", img: "PICTURES-OJAS/80W90/80W90 (2).jpg" },
    { id: 6, name: "CUTTING OIL", img: "PICTURES-OJAS/CUTTING OIL/CUTTING OIL.jpg" },
    { id: 7, name: "OX - 52", img: "PICTURES-OJAS/OX - 52/BHP_8093.jpg" },
    { id: 8, name: "AP - 3", img: "PICTURES-OJAS/GREASE AP3/BHP_8095.jpg" },
  ];

  const settings = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div className="product-slider-container">
      <h2 style={{padding: '10px'}}>Our Top Products</h2>
      <Slider {...settings}>
        {products.map((product) => (
          <div key={product.id} className="product-item">
            <img src={product.img} alt={product.name} />
            <p>{product.name}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ProductSlider;
