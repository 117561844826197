import React from 'react';
import Header from './Header.js';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import Footer from './Footer/Footer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './HomePage.js';
import { LubricantsPage, IndustrialOilsPage, AutomotiveOilsPage, GreasesPage, GearOilsPage, OtherProductsPage } from './Products';
import LocationPage from './Location.js';
import ScrollReveal from './Scroll-Reveal/ScrollReveal'; // Import the ScrollReveal component

const App = () => {
  return (
    <>
      <Router>
        <>
          <FloatingWhatsApp
            phoneNumber="9711194397" // Replace with your WhatsApp phone number
            accountName="Ojas Helpline" // Replace with your WhatsApp account name
          />
          <ScrollReveal>
            <Header />
          </ScrollReveal>
          <Routes>
            <Route
              path="/"
              element={
                <ScrollReveal>
                  <HomePage />
                </ScrollReveal>
              }
            />
            <Route
              path="/Lubricants"
              element={
                <ScrollReveal>
                  <LubricantsPage />
                </ScrollReveal>
              }
            />
            <Route
              path="/IndustrialOils"
              element={
                <ScrollReveal>
                  <IndustrialOilsPage />
                </ScrollReveal>
              }
            />
            <Route
              path="/AutomotiveOils"
              element={
                <ScrollReveal>
                  <AutomotiveOilsPage />
                </ScrollReveal>
              }
            />
            <Route
              path="/Greases"
              element={
                <ScrollReveal>
                  <GreasesPage />
                </ScrollReveal>
              }
            />
            <Route
              path="/GearOils"
              element={
                <ScrollReveal>
                  <GearOilsPage />
                </ScrollReveal>
              }
            />
            <Route
              path="/OtherProducts"
              element={
                <ScrollReveal>
                  <OtherProductsPage />
                </ScrollReveal>
              }
            />
            <Route
              path="/Location"
              element={
                <ScrollReveal>
                  <LocationPage />
                </ScrollReveal>
              }
            />
            <Route
              path="/AboutUs"
              element={
                <ScrollReveal>
                  <LocationPage />
                </ScrollReveal>
              }
            />
          </Routes>
          <ScrollReveal>
            <Footer />
          </ScrollReveal>
        </>
      </Router>
    </>
  );
};

export default App;
